import React from 'react'
// import whatsapp from '../../public/img/whatsapp.png'

const Whatsapp = () => {
  return (
    <div>
    <a href="https://wa.me/905412143408" target="_blank" rel="noopener noreferrer">
    <img src="/img/whatsapp.png" className="h-14 fixed bottom-4 right-4" alt="WhatsApp Icon" />
  </a>
    </div>
  )
}

export default Whatsapp